<template>
  <section>
    <div
      class="table" >
      <div class="th">
        <span>Insured Details</span>
      </div>
      <div
        v-if="isShow"
        class="tr">
        <span>Occupied As</span>
        <span>{{getOptionName('globalOptions.occupied_as',insuredDetail.occupied_as_id)}}</span>
      </div>
      <div
        v-if="isShow"
        class="tr">
        <span>Property Age</span>
        <span>{{getOptionName('globalOptions.property_age',insuredDetail.property_age_id)}}</span>
      </div>
      <div
        v-if="isShow"
        class="tr">
        <span>Building Type</span>
        <span>{{getOptionName('globalOptions.building_type',insuredDetail.build_type_id)}}</span>
      </div>
      <div
        v-if="insuredDetail.build_type_id==4"
        class="tr">
        <span>Other</span>
        <span>{{insuredDetail.build_type}}</span>
      </div>
      <div
        class="tr">
        <span>Proposed Location of Risk Address</span>
        <span>{{address}}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
export default {
  name: 'InsuredDetailInfo',
  mixins: [MixinOptions],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isShow:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    insuredDetail(){
      let {insured_detail} = this.form||{}
      return insured_detail||{}
    },
    address() {
      const {insured_detail} =this.form||{}
      const {proposed_location_of_risk} =insured_detail||{}
      if(proposed_location_of_risk)
      {
        let arr = [
          proposed_location_of_risk.room,
          proposed_location_of_risk.floor,
          proposed_location_of_risk.block,
          proposed_location_of_risk.building,
          proposed_location_of_risk.street,
          this.getAreaName('district', proposed_location_of_risk.district),
          this.getAreaName('area', proposed_location_of_risk.area),
          this.getAreaName('region', proposed_location_of_risk.region)
        ]
        return arr.filter(item => item).join(', ')
      }else  return ''
    },
    productId(){
      return this.form.product_id
    },
    isShowOccauption(){
      let a = [10,12,13]
      return a.includes(this.form.product_id)
    },
    insured_detail(){
      let {insured_detail} = this.form||{}
      return insured_detail||{}
    }
  }
}
</script>

<style scoped>

</style>
