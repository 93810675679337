<template>
  <div class="table">
    <div class="th">
      <span>Remarks</span>
    </div>
    <div
      class="tr">
      <!--      <span v-html="remark"></span>-->
      <p v-html="remark"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'remarkInfo',
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    remark(){
      let {remark} = this.form
      return remark?remark.replace(/\n/g, '<br/>'):'-'
    }
  }
}
</script>

<style scoped>

</style>
