<template>
  <table
    class="table-list"
    :class="{'table-list__premium':isPremium}">
    <tr class="th">
      <th >Document</th>
      <th
        v-if="isPremium"
      >Created Date</th>
    </tr>
    <tr>
      <td>
        <span>Quotation <span class="litte-span">Gross Premium</span></span>
        <span
          v-if="loadingDone('quotation_pdf_g_path')"
          class="theme-second-blue hand"
          @click="openFile(quotation_document_gross)">{{quotation_document_gross.client_name||'-'}}</span>
        <span
          v-else
          class="text-center blue icon-loading-size">
          <i class="el-icon-loading"></i>
          <span class="update-text theme-second-blue">Updating</span>
        </span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in quotation_document_gross.history"
          :key="'quotation_document_gross'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span>Quotation <span class="litte-span">Total Payable</span></span>
        <span
          v-if="loadingDone('quotation_pdf_path')"
          class="theme-second-blue hand"
          @click="openFile(quotation_document)">{{quotation_document.client_name||'-'}}</span>
        <span
          v-else
          class="text-center blue icon-loading-size">
          <i class="el-icon-loading"></i>
          <span class="update-text theme-second-blue">Updating</span>
        </span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in quotation_document.history"
          :key="'quotation_document'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span>Invoice <span class="litte-span">Gross Premium</span></span>
        <span
          v-if="loadingDone('inv_pdf_g_path')"
          class="theme-second-blue hand"
          @click="openFile(invoice_document_gross)">{{invoice_document_gross.client_name||'-'}}</span>
        <span
          v-else
          class="text-center blue icon-loading-size">
          <i class="el-icon-loading"></i>
          <span class="update-text theme-second-blue">Updating</span>
        </span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in invoice_document_gross.history"
          :key="'quotation_document_gross'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span>Invoice  <span class="litte-span">Total Payable</span> </span>
        <span
          v-if="loadingDone('inv_pdf_path')"
          class="theme-second-blue hand"
          @click="openFile(invoice_document)">{{invoice_document.client_name||'-'}}</span>
        <span
          v-else
          class="text-center blue icon-loading-size">
          <i class="el-icon-loading"></i>
          <span class="update-text theme-second-blue">Updating</span>
        </span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in invoice_document.history"
          :key="'invoice_document'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr >
      <td>
        <span>Receipt  <span class="litte-span">Gross Premium</span></span>
        <span
          v-if="loadingDone('rec_pdf_g_path')"
          class="theme-second-blue hand"
          @click="openFile(receipt_document_gross)">{{receipt_document_gross.client_name||'-'}}</span>
        <span
          v-else
          class="text-center blue icon-loading-size">
          <i class="el-icon-loading"></i>
          <span class="update-text theme-second-blue">Updating</span>
        </span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in receipt_document_gross.history"
          :key="'receipt_document'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr >
      <td>
        <span>Receipt  <span class="litte-span">Total Payable</span></span>
        <span
          v-if="loadingDone('rec_pdf_path')"
          class="theme-second-blue hand"
          @click="openFile(receipt_document)">{{receipt_document.client_name||'-'}}</span>
        <span
          v-else
          class="text-center blue icon-loading-size">
          <i class="el-icon-loading"></i>
          <span class="update-text theme-second-blue">Updating</span>
        </span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in receipt_document.history"
          :key="'receipt_document'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr
      v-if="!isCompany"
      class="alt">
      <td>
        <span>HKID Card / Passport</span>
        <span
          class="theme-second-blue hand"
          @click="openFile(hkid_file)">{{hkid_file.client_name||'-'}}</span>

      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in hkid_file.history"
          :key="'hkid_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr
      v-else
      class="alt">
      <td>
        <span>CI / BR</span>
        <span
          class="theme-second-blue hand"
          @click="openFile(br_file)">{{br_file.client_name||'-'}}</span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in br_file.history"
          :key="'br_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span>Vehicle Registration Document</span>
        <span
          class="theme-second-blue hand"
          @click="openFile(vrd_file)">{{vrd_file.client_name||'-'}}</span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in vrd_file.history"
          :key="'vrd_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr class="alt">
      <td>
        <span>Driving Licence</span>
        <span
          class="theme-second-blue hand"
          @click="openFile(driving_licence_file)">{{driving_licence_file.client_name||'-'}}</span>

      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in driving_licence_file.history"
          :key="'ncb_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr
      v-if="form.ncb_file"
      class="alt">
      <td>
        <span>NCB proof (e.g. Renewal Notice)</span>
        <span
          class="theme-second-blue hand"
          @click="openFile(ncb_file)">{{ncb_file.client_name||'-'}}</span>

      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in ncb_file.history"
          :key="'ncb_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr v-if="isCompany">
      <td>
        <span>Driver HKID Card / Passport</span>
        <span
          class="theme-second-blue hand"
          @click="openFile(hkid_file)">{{hkid_file.client_name||'-'}}</span>
      </td>
      <td v-if="isPremium">
        <span
          v-for="(item,index) in hkid_file.history"
          :key="'hkid_file'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr class="alt">
      <td>
        <span>Other Document</span>
        <span>
          <div
            v-for="(item,index) in other_documents.data"
            :key="index"
            class="theme-second-blue hand block"
            @click="openFile(item)">
            {{item.client_name}}
          </div>
        </span>
      </td>
      <td  v-if="isPremium">
        <span
          v-for="(item,index) in other_documents.history"
          :key="'documents'+index"
          class="block">
          <span>{{item.date}}</span>
          <span>{{item.created_by}}</span>
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span>Full Set Doc. Received?</span>
        <span>{{getOptionName('yesNoOptions',form.documents_received)}}</span>
      </td>
      <td v-if="isPremium"></td>
    </tr>
  </table>

</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
import {getQuotationStatus} from '@/api/quotation'
export default {
  name: 'documentInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isPremium:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return{
      setInterval: null,
      isLoading:true,
      loadingData:{},
      document:{}
    }
  },
  computed:{
    order_no(){
      let {order_no} = this.$route.query||{}
      return order_no||''
    },
    loadingDone(){
      return (name) =>{
        if(!this.order_no) return true
        return this.loadingData[name]==0
      }
    },
    isCompany(){
      return this.form.client_type==2
    },
    productId(){
      return this.form.product_id
    },
    isEndorsement(){
      return this.form.policy_type==203
    },
    hkid_file(){
      return  this.form.hkid_file||{}
    },
    other_documents(){
      return  this.form.other_documents||{}
    },
    br_file(){
      return  this.form.br_file||{}
    },
    vrd_file(){
      return  this.form.vrd_file||{}
    },
    ncb_file(){
      return  this.form.ncb_file||{}
    },
    driving_licence_file(){
      return  this.form.driving_licence_file||{}
    },
    documentObj(){
      if(this.order_no)
        return  this.document
      else return  this.form
    },
    quotation_document(){
      return  this.documentObj.quotation_document||{}
    },
    quotation_document_gross(){
      return  this.documentObj.quotation_gross_document||{}
    },
    receipt_document(){
      return  this.documentObj.receipt_document||{}
    },
    receipt_document_gross(){
      return  this.documentObj.receipt_gross_document||{}
    },
    invoice_document(){
      return  this.documentObj.invoice_document||{}
    },
    invoice_document_gross(){
      return  this.documentObj.invoice_gross_document||{}
    },
    documentList(){
      let list = {
        10:this.documentOptions,
        11:this.contractorDocumentOptions,
        12:this.travelDocumentOptions,
        13:this.travelDocumentOptions,
        14:this.employeeDocumentOptions,
        15:this.businessDocumentOptions,
        16:this.travelDocumentOptions,
        17:this.conEmployeeDocumentOptions,
        18:this.travelDocumentOptions
      }
      let {class_id,client_type} = this.form||{}
      let result = []
      this.globalOptions.document_type.some(item=>{

        if (item.class_id == class_id) {
          item.data.some(client=>{
            if(client.client_type==client_type)
              result = client.items
          })
        }
      })
      return result
      return list[this.productId]
    },
  },
  mounted() {
    if(this.order_no)
    {
      this.network().getQuotationStatus()
      this.setInterval = setInterval(() => {
        this.network().getQuotationStatus()
      }, 1000)
    }
  },
  beforeDestroy() {
    clearInterval(this.setInterval)
  },
  methods:{
    openFile(data) {
      const { url } = data
      if(url) {
        window.open(url, '_blank')
      }
    },
    network(){
      return{
        getQuotationStatus:async (params) =>{
          let {order_no} = this.$route.query||{}
          let {data} = await getQuotationStatus({hideLoading: true,order_no}).catch(err=>{
            if(err){
              clearInterval(this.setInterval)
            }
          })
          this.loadingData = data
          this.document = data.documents||{}
          let documentObj = JSON.parse(JSON.stringify(data))
          delete documentObj.documents
          let isComplete = Object.values(documentObj).every(item=>item==0)
          if(isComplete){
            this.isLoading = false
            clearInterval(this.setInterval)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
    .theme-second-blue{
      margin-bottom: 8px;
    }
    .litte-span{
      font-weight: 400!important;
      font-size: 12px !important;
      line-height: 150%;
      color: #2D3748 !important;
      padding: 0;
    }
    .icon-loading-size{
      height: auto!important;
      justify-content:flex-start!important;
      font-size: 14px!important;
      line-height: 14px;
    }
    .update-text{
      //font-size: 12px!important;
      margin-bottom: 0px;
      margin-left: 4px;
      color: $gray;
      padding: 0;
      text-align: left;
    }
</style>
