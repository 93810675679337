<template>
  <section>
    <policy-detail-info :form="formData"></policy-detail-info>
    <policy-holder-detail-info :form="formData"></policy-holder-detail-info>
    <insured-detail-info
      :form="formData"
      :isShow="false"></insured-detail-info>
    <domestic-helper-info :form="formData.domestic_helper_detail"></domestic-helper-info>
    <remark-info :form="formData"></remark-info>
    <document-info :form="formData"></document-info>
    <referral-info :form="formData.referral"></referral-info>
  </section>
</template>

<script>
import policyDetailInfo from '@/views/components/info/policyDetailInfo'
import policyHolderDetailInfo from '@/views/components/info/policyHolderDetailInfo'
import insuredDetailInfo from '@/views/components/info/insuredDetailInfo'
import remarkInfo from '@/views/components/info/remarkInfo'
import documentInfo from '@/views/components/info/documentInfo'
import referralInfo from '@/views/components/info/referralInfo'
import DomesticHelperInfo from '@/views/components/info/domesticHelperInfo'

export default {
  name: 'travelInfo',
  components:{
    DomesticHelperInfo,
    policyDetailInfo,
    policyHolderDetailInfo,
    remarkInfo,
    documentInfo,
    referralInfo,
    insuredDetailInfo
  },
  props:{
    formData:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    coverage(){
      return this.formData.coverage||{}
    }
  },
}
</script>
