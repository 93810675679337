<template>
  <div class="table">
    <div class="th">
      <span>Coverage</span>
    </div>
    <div v-if="productId==10">
      <div v-if="form.class_id!==1004">
        <div
          class="tr orgin_tr">
          <span>Cover Type</span>
          <span>{{ getOptionName('coverTypeOptions', coverage.cover_type)}}
          </span>
        </div>
        <div v-if="form.class_id!==1003">
          <div
            v-if="form.cover_type==2"
            class="tr">
            <span>Estimated Vehicle Value</span>
            <span>{{toFixed(coverage.estimated_vehicle_value)}}</span>
          </div>
          <div
            class="tr">
            <span>Hire Purchase Owner</span>
            <span>{{getOptionName('yesNoOptions',coverage.hire_purchase_owner)}}</span>
          </div>
          <div
            v-if="coverage.hire_purchase_owner==1"
            class="tr">
            <span>Hire Purchase Owner（If Yes for Hire purchase）</span>
            <span v-if="coverage.hire_purchase_owner_id!=100">{{getOptionName('globalOptions.hire_purchase',coverage.hire_purchase_owner_id)}}</span>
            <span v-else>{{getOptionName('globalOptions.hire_purchase',coverage.hire_purchase_owner_id)+(coverage.hire_purchase_owner_value?'-'+coverage.hire_purchase_owner_value:'')}}</span>
          </div>
          <div
            v-if="coverage.hire_purchase_owner==1"
            class="tr">
            <span>Policy send to Hire Purchase Owner</span>
            <span>{{showHkDate(coverage.policy_send_to_hire_purchase_owner_date)}}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="tr">
          <span>Plan</span>
          <span>{{ getOptionName('planOptions', coverage.cover_type)}}
          </span>
        </div>
        <div
          class="tr">
          <span>Demonstration Extension Risk Cover</span>
          <span>{{ getOptionName('yesNoOptions', coverage.demonstration_extension_risk_cover)}}
          </span>
        </div>
        <div
          class="tr">
          <span>Insured Amount (HKD)</span>
          <span>{{toFixed(coverage.insured_amount)}}
          </span>
        </div>
        <div
          class="tr">
          <span>Premium (HKD)</span>
          <span>{{toFixed(coverage.premium)}}
          </span>
        </div>
      </div>
    </div>
    <div v-if="productId==11">
      <div
        class="tr">
        <span>Scope of Work</span>
        <span>
          {{coverage.scope_of_work}}
        </span>
      </div>
      <div
        class="tr">
        <span>Work Site Location</span>
        <span>{{coverage.work_site_location}}
        </span>
      </div>
      <div
        class="tr">
        <span>Contract Value</span>
        <span>{{toFixed(coverage.contract_value)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Section 1 - Material Damage</span>
        <span>{{ getOptionName('yesNoOptions', coverage.section_1)}}
        </span>
      </div>
      <div
        v-if=" coverage.section_1==1"
        class="tr">
        <span>Insured Amount ( Material Damage )</span>
        <span>{{toFixed(coverage.insured_amount_material_damage)}}
        </span>
      </div>
      <div
        v-if=" coverage.section_1==1"
        class="tr">
        <span>Premium ( Material Damage )</span>
        <span>{{toFixed(coverage.premium_material_damage)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Section 2 - Third Party Liability</span>
        <span>{{ getOptionName('yesNoOptions', coverage.section_2)}}
        </span>
      </div>
      <div
        v-if=" coverage.section_2==1"
        class="tr">
        <span>Insured Amount ( Third Party Liability )</span>
        <span>
          {{ coverage.insured_amount_third_party||0}} Million
        </span>
      </div>
      <div
        v-if=" coverage.section_2==1"
        class="tr">
        <span>Premium (Third Party Liability)</span>
        <span>{{toFixed(coverage.premium_third_party)}}
        </span>
      </div>
    </div>
    <div v-if="productId==13">
      <div
        class="tr">
        <span>Home Contents All Risk（Insured Amount（HKD））</span>
        <span>
          {{coverage.home_contents_all_risk.insured_amount}}
        </span>
      </div>
      <div
        class="tr">
        <span>Home Contents All Risk（Premium（HKD））</span>
        <span>
          {{coverage.home_contents_all_risk.premium}}

        </span>
      </div>
      <div
        class="tr">
        <span>Personal Accident（Insured Amount（HKD））</span>
        <span>
          {{coverage.personal_accident.insured_amount}}

        </span>
      </div>
      <div
        class="tr">
        <span>Personal Accident（Premium（HKD））</span>
        <span>
          {{coverage.personal_accident.premium}}

        </span>
      </div>
      <div
        class="tr">
        <span>Personal Liability（Insured Amount（HKD））</span>
        <span>
          {{coverage.personal_liability.insured_amount}}

        </span>
      </div>
      <div
        class="tr">
        <span>Personal Liability（Premium（HKD））</span>
        <span>
          {{coverage.personal_liability.premium}}

        </span>
      </div>
      <div class="th">
        <span>Optional Benifit</span>
      </div>
      <div
        class="tr">
        <span>Domestic Helpers</span>
        <span>{{ getOptionName('yesNoOptions', coverage.optional_benefit.domestic_helpers)}}
        </span>
      </div>
      <div
        v-if="coverage.optional_benefit.domestic_helpers"
        class="tr">
        <span>Premium （Domestic Helpers）</span>
        <span>{{toFixed(coverage.optional_benefit.domestic_helpers_premium)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Building Structure</span>
        <span>{{ getOptionName('yesNoOptions', coverage.optional_benefit.building)}}
        </span>
      </div>
      <div
        v-if="coverage.optional_benefit.building"
        class="tr">
        <span>Insured Amount （Building）</span>
        <span>{{toFixed(coverage.optional_benefit.building_insured_amount)}}
        </span>
      </div>
      <div
        v-if="coverage.optional_benefit.building"

        class="tr">
        <span>Premium （Building）</span>
        <span>{{toFixed(coverage.optional_benefit.building_premium)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Other</span>
        <span>{{ getOptionName('yesNoOptions', coverage.optional_benefit.other)}}
        </span>
      </div>
      <div
        v-if="coverage.optional_benefit.other==1"
        class="tr">
        <span>Content （Other）</span>
        <span>{{coverage.optional_benefit.other_content}}
        </span>
      </div>
      <div
        v-if="coverage.optional_benefit.other==1"
        class="tr">
        <span>Premium （Other）</span>
        <span>{{toFixed(coverage.optional_benefit.other_premium)}}
        </span>
      </div>
    </div>
    <div v-if="productId==15">
      <div
        class="tr">
        <span>Contents（Insured Amount（HKD））</span>
        <span>
          {{toFixed(coverage.contents.insured_amount)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Contents（Premium（HKD））</span>
        <span>
          {{toFixed(coverage.contents.premium)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Business Interruption Insurance（Insured Amount（HKD））</span>
        <span>
          {{toFixed(coverage.business_interruption_insurance.insured_amount)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Business Interruption Insurance（Premium（HKD））</span>
        <span>
          {{toFixed(coverage.business_interruption_insurance.premium)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Money Insurance（Insured Amount（HKD））</span>
        <span>
          {{toFixed(coverage.money_insurance.insured_amount)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Money Insurance（Premium（HKD））</span>
        <span>
          {{toFixed(coverage.money_insurance.premium)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Fidelity Guarantee Insurance（Insured Amount（HKD））</span>
        <span>
          {{toFixed(coverage.fidelity_guarantee_insurance.insured_amount)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Fidelity Guarantee Insurance（Premium（HKD））</span>
        <span>
          {{toFixed(coverage.fidelity_guarantee_insurance.premium)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Personal Accident Assault Insurance（Insured Amount（HKD））</span>
        <span>
          {{toFixed(coverage.personal_accident_assault_insurance.insured_amount)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Personal Accident Assault Insurance（Premium（HKD））</span>
        <span>
          {{toFixed(coverage.personal_accident_assault_insurance.premium)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Public Liability Insurance （Insured Amount（HKD））</span>
        <span>
          {{toFixed(coverage.public_liability_insurance.insured_amount)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Public Liability Insurance（Premium（HKD））</span>
        <span>
          {{toFixed(coverage.public_liability_insurance.premium)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Employees’ Compensation Insurance（Insured Amount（HKD））</span>
        <span>
          {{toFixed(coverage.employees_compensation_insurance.insured_amount)}}

        </span>
      </div>
      <div
        class="tr">
        <span>Employees’ Compensation Insurance（Premium（HKD））</span>
        <span>
          {{toFixed(coverage.employees_compensation_insurance.premium)}}

        </span>
      </div>
    </div>
    <div v-if="productId==16">
      <div
        class="tr">
        <span>Contents（Insured Amount（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Contents（Premium（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Business Interruption Insurance（Insured Amount（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Business Interruption Insurance（Premium（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Money Insurance（Insured Amount（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Money Insurance（Premium（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Fidelity Guarantee Insurance（Insured Amount（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Fidelity Guarantee Insurance（Premium（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Personal Accident Assault Insurance（Insured Amount（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Personal Accident Assault Insurance（Premium（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Employees' Compensation Insurance（Insured Amount（HKD））</span>
        <span>
        </span>
      </div>
      <div
        class="tr">
        <span>Employees' Compensation Insurance（Premium（HKD））</span>
        <span>
        </span>
      </div>
    </div>
    <div v-if="productId==17">
      <div
        class="tr">
        <span>Scope of Work</span>
        <span>
          {{coverage.scope_of_work}}
        </span>
      </div>
      <div
        class="tr">
        <span>Work Site Location</span>
        <span>
          {{coverage.work_site_location}}
        </span>
      </div>
      <div
        class="tr">
        <span>Contract Value</span>
        <span>
          {{coverage.contract_value}}
        </span>
      </div>
      <div
        class="tr">
        <span>Premium （Employees‘ Compensation Insurance）</span>
        <span>
          {{coverage.premium}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
export default {
  name: 'coverageInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    coverage(){
      return this.form.coverage||{}
    },
    productId(){
      return this.form.product_id
    }
  },
  methods:{

    toFixed(data) {
      const arr = String(data).split('.')
      let num = arr[0] // 整数部分
      let resultArr = []
      while (num.length > 0) {
        resultArr.unshift(num.slice(-3))
        num = num.slice(0, -3)
      }
      let result = resultArr.join(',')
      return '$'+result
    }
  }
}
</script>

<style scoped>

</style>
