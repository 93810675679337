<template>
  <section>
    <policy-detail-info :form="formData"></policy-detail-info>
    <policy-holder-detail-info :form="formData"></policy-holder-detail-info>
    <coverage-info :form="formData"></coverage-info>
    <remark-info :form="formData"></remark-info>
    <document-info :form="formData"></document-info>
    <referral-info :form="formData.referral"></referral-info>
  </section>
</template>

<script>
import policyDetailInfo from '@/views/components/info/policyDetailInfo'
import policyHolderDetailInfo from '@/views/components/info/policyHolderDetailInfo'
import coverageInfo from '@/views/components/info/coverageInfo'
import remarkInfo from '@/views/components/info/remarkInfo'
import documentInfo from '@/views/components/info/documentInfo'
import referralInfo from '@/views/components/info/referralInfo'

export default {
  name: 'constructionInfo',
  components:{
    policyDetailInfo,
    policyHolderDetailInfo,
    remarkInfo,
    documentInfo,
    referralInfo,

    coverageInfo,

  },
  props:{
    formData:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    coverage(){
      return this.formData.coverage||{}
    }
  },
}
</script>
