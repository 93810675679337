<template>
  <div>
    <div      class="table">
      <div class="th">
        <span>Driver Details</span>
      </div>
      <div
        class="tr">
        <span>Are all the driver(s) has not been involved in a motor accident in the past 3 years?</span>
        <span v-if="first_drivers.has_accident">{{getOptionName('accidentsOption', first_drivers.has_accident)}}
        </span>
        <span v-else></span>
      </div>
      <div
        v-if=" first_drivers.has_accident==2"
        class="tr">
        <span>Number of accident in last 3 years</span>
        <span v-if="first_drivers.accidents">{{getOptionName('globalOptions.accidents',first_drivers.accidents)}}
        </span>
        <span v-else></span>
      </div>
      <div
        class="tr">
        <span>Are all the driver(s) has not been deducted driving offence points in the past 3 years?</span>
        <span >{{getOptionName('accidentsOption',first_drivers.is_deducted_point)}}
        </span>
      </div>
      <div
        v-if="first_drivers.is_deducted_point==2"
        class="tr">
        <span>Number of driving offence point in last 3 years</span>
        <span v-if="first_drivers.deducted_points">{{getOptionName('globalOptions.deducted_points', first_drivers.deducted_points)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Policyholder is one of the Named Driver?</span>
        <span v-if="form.policy_holder_is_driver">{{ getOptionName('yesNo2Options', form.policy_holder_is_driver) }}</span>
        <span v-else></span>
      </div>
    </div>
    <div
      v-for="(item,index) in drivers"
      :key="index"
      class="table"
    >
      <div class="th">
        <span>Driver{{index+1}}</span>
      </div>
      <div
        class="tr">
        <span>Relationship with Proposer</span>
        <span>{{ getOptionName('relationshipOptions', item.relationship_id)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Title</span>
        <span>{{getOptionName('titleOption',item.title)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Name</span>
        <span>{{item.name}}
        </span>
      </div>
      <div
        class="tr">
        <span>Date of Birth</span>
        <span>{{showHkDate(item.birth)}}
        </span>
      </div>
      <div
        class="tr">
        <span>{{ getOptionName('personalIdTypOptions',item.id_type) }}</span>
        <span>{{item.id_no}}
        </span>
      </div>
      <div
        class="tr">
        <span>Occupation</span>
        <span>{{item.occ}}
        </span>
      </div>
      <div
        v-if="form.class_id==1003"
        class="tr">
        <span>Type of Motorcycle Driving licence</span>
        <span>{{getOptionName('globalOptions.driving_licence', item.driving_licence_id)}}
        </span>
      </div>
      <div
        v-else
        class="tr">
        <span>Driving Experience</span>
        <span>{{getOptionName('globalOptions.driving_experience', item.driver_experience_id)}}
        </span>
      </div>
      <div
        v-if="form.class_id==1003&&item.driving_licence_id==3"
        class="tr">
        <span>Year of driving Experience</span>
        <span>{{getOptionName('globalOptions.driving_experience', item.driver_experience_id)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Number of driving offence point in last 3 years</span>
        <span>{{getOptionName('globalOptions.deducted_points', item.deducted_points)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Have any been involved in any car accident or motor claim in the past 3 years?</span>
        <span>
          <span class="block">{{getOptionName('yesNo3Options', item.has_accident)}}</span>
          <span v-if="item.has_accident==2">{{item.accident_reason}}</span>
        </span>
      </div>
      <div
        class="tr">
        <span>Have any been declined motor insurance, had a motor insurance policy cancelled?</span>
        <span>
          <span class="block">{{getOptionName('yesNo2Options', item.is_declined)}}</span>
          <span v-if="item.is_declined==1">{{item.declined_reason}}</span>
        </span>
      </div>
      <div
        class="tr">
        <span>Have any suffered from any physical or mental infirmity that may affect his/her ability to drive?</span>
        <span>
          <span class="block">{{getOptionName('yesNo2Options', item.is_no_driving_ability)}}</span>
          <span v-if="item.is_no_driving_ability==1">{{item.no_driving_ability_reason}}</span>
        </span>
      </div>
      <div
        class="tr">
        <span>Have been prosecuted by police, Dangerous Driving or driving licence suspended or disqualified by court ?</span>
        <span>
          <span class="block">{{getOptionName('yesNo2Options', item.is_dangerous_driving)}}</span>
          <span v-if="item.is_dangerous_driving==1">{{item.dangerous_driving_reason}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MixinOption from '@/mixins/options'

export default {
  name: 'diverInfo',
  mixins: [MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    drivers(){
      let {drivers=[{}]} = this.form||{}
      return drivers||[{}]
    },
    first_drivers(){
      let arr = this.drivers||[]
      return this.drivers.length>0?arr[0]:{}
    }
  }
}
</script>

<style scoped>

</style>
