<template>
  <div>
    <div
      class="table"
    >
      <div
        class="th">
        <span>Domestic Helper's Detail</span>
      </div>
      <div
        class="tr">
        <span>Name</span>
        <span>
          {{form.name}}
        </span>
      </div>
      <div
        class="tr">
        <span>{{ getOptionName('personalIdTypOptions',form.id_type) }}</span>
        <span>
          {{form.id_no}}
        </span>
      </div>
      <div
        class="tr">
        <span>Date of Birth</span>
        <span>{{showHkDate(form.birth_date)}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MixinOption from '@/mixins/options'

export default {
  name: 'domesticHelperInfo',
  mixins: [MixinOption],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  }
}
</script>

<style scoped>

</style>
