import { render, staticRenderFns } from "./domesticHelperInfo.vue?vue&type=template&id=45e7ecf2&scoped=true&"
import script from "./domesticHelperInfo.vue?vue&type=script&lang=js&"
export * from "./domesticHelperInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e7ecf2",
  null
  
)

export default component.exports