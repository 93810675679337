<template>
  <div>
    <div
      v-for="(item,index) in form"
      :key="index"
      class="table"
    >
      <div class="th">
        <span>Insured Location {{index+1}}</span>
      </div>
      <div
        class="tr">
        <span>Address</span>
        <span>
          {{address(item)}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MixinOption from '@/mixins/options'

export default {
  name: 'travelerInfo',
  mixins: [MixinOption],
  props:{
    form:{
      type:Array,
      default:()=>([])
    }
  },
  methods:{
    address(item) {

      let arr = [
        item.room,
        item.floor,
        item.block,
        item.building,
        item.street,
        this.getAreaName('district', item.district),
        this.getAreaName('area', item.area),
        this.getAreaName('region', item.region)
      ]
      return arr.filter(item => item).join(', ')
    },
  }
}
</script>

<style scoped>

</style>
