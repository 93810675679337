<template>
  <div class="table">
    <div class="th">
      <span>Signature</span>
    </div>
    <div
      class="tr">
      <span v-if="!sign_pic">-</span>
      <div
        v-else
        class="sign-pic">
        <img :src="sign_pic">
      </div>
    </div>
  </div>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOption from '@/mixins/options'
export default {
  name: 'signatureInfo',
  mixins: [MixinFormat,MixinOption],
  props:{
    form:{
      type:[Object,Array],
      default:()=>({})
    }
  },
  computed:{
    sign_pic(){
      let {upload_document={},signature={}} = this.form
      let sign_data = signature||{}
      return sign_data.url
    },
  }
}
</script>

<style scoped lang="scss">
.sign-pic{
  width: 335px;
  background: #fff;
  height: 200px;
  border-radius: 8px;
}
</style>
