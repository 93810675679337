<!--Policy Detail Info-->
<template>
  <section>
    <div class="table">
      <div class="th">
        <span>Insurer</span>
      </div>
      <div
        class="tr">
        <span>Insurer</span>
        <span>{{getOptionName('globalOptions.company',form.company_id)}}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import {mapActions, mapState} from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'insureInfo',
  mixins: [MixinOptions],
  props:{
    form:{
      type:Object,
      default:()=>({})
    }
  },
  computed:{
    ...mapState('quotation', ['productID']),
  }
}
</script>

<style scoped lang="scss">
</style>
