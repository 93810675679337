<template>
  <div>
    <div
      v-for="(item,index) in form"
      :key="index"
      class="table"
    >
      <div class="th">
        <span>Insured Employee {{index+1}}</span>
      </div>
      <div
        class="tr">
        <span>Occupations of Employee</span>
        <span>
          {{item.occ_of_employee}}
        </span>
      </div>
      <div
        class="tr">
        <span>Indoor / Outdoor</span>
        <span>{{ getOptionName('insuredEmployeeTypeOption', item.type)}}
        </span>
      </div>
      <div
        class="tr">
        <span>Number of Employees</span>
        <span>{{item.number_of_employee}}
        </span>
      </div>
      <div
        class="tr">
        <span>Est. Annual Earnings (HKD)</span>
        <span>{{item.est_annual_earnings}}
        </span>
      </div>

    </div>
    <div

      class="table"
    >
      <div class="th">
        <span>Total</span>
      </div>
      <div
        class="tr">
        <span>Number of Employees(Total)</span>
        <span>
          {{totalEmployees}}
        </span>
      </div>
      <div
        class="tr">
        <span>Est. Annual Earnings (Total HKD)</span>
        <span>
          {{totalEarnings}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MixinOption from '@/mixins/options'

export default {
  name: 'insuredEmployeeInfo',
  mixins: [MixinOption],
  props:{
    form:{
      type:Array,
      default:()=>([])
    }
  },
  computed:{
    totalEmployees(){
      let total = 0
      this.form.forEach(item=>{
        total = Number(item.number_of_employee) +total
      })
      return total
    },
    totalEarnings(){
      let {insured_employee=[]} = this.form||{}
      let total = 0
      this.form.forEach(item=>{
        total = Number(item.est_annual_earnings) +total
      })
      return total
    }
  },
}
</script>

<style scoped>

</style>
